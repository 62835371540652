@import '/src/styles/utilities.scss';

.formFollowFeature {
  max-height: 50vh;
  overflow: auto;
  border: 1px solid var(--color-gray-200);
  border-radius: $border-radius-10;
}

.formFollowFeature__page {
  & + & {
    border-top: 1px solid var(--color-gray-200);
  }

  > * {
    // Stretch to full width for maximum clickability
    width: 100%;
    padding: $space-16;
  }

  &:has(input:checked) {
    background-color: var(--color-blue-300);
  }

  &:has(label:hover),
  &:has(input:hover) {
    background-color: var(--color-blue-200);
  }
}
